<template>
  <div>
    <el-card>
      <div class="time">
        填报日期：
        <el-date-picker
          class="picker"
          v-model="activeTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          @change="changeGrantTime"
          unlink-panels
        >
        </el-date-picker>
      </div>
      <div class="account">
        会员账号
        <el-input
          placeholder=""
          class="input"
          v-model="queryInfo.memberAccount"
          clearable
          @change="searchDistribution"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchDistribution"
          ></el-button>
        </el-input>
      </div>
      <div class="contact">
        商户联系方式
        <el-input
          placeholder=""
          class="input"
          v-model="queryInfo.merchantContact"
          clearable
          @change="searchDistribution"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchDistribution"
          ></el-button>
        </el-input>
      </div>
      <div class="check">
        审核情况
        <el-select
          class="select"
          v-model="queryInfo.status"
          placeholder="请选择"
          clearable
          @clear="getDistribution"
          @change="searchDistribution"
        >
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-table
        border
        stripe
        :data="distributionList"
      >
        <el-table-column
          width="80"
          label="id"
          prop="id"
        ></el-table-column>
        <el-table-column
          label="填报日期"
          prop="createTime"
        ></el-table-column>
        <el-table-column
          label="会员账号"
          prop="memberAccount"
        ></el-table-column>
        <el-table-column
          label="联系方式"
          prop="merchantContact"
        ></el-table-column>
        <el-table-column
          label="推荐商户"
          prop="merchantName"
        ></el-table-column>
        <el-table-column
          label="审核情况"
          prop="status"
        >
          <template slot-scope="scope">
            <el-tag
              type=""
              v-if="scope.row.status ===0"
            >未审核</el-tag>
            <el-tag
              type="success"
              v-else-if="scope.row.status ===1"
            >已审核</el-tag>
            <el-tag
              type="danger"
              v-else-if="scope.row.status ===2"
            >已拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="审核日期"
          prop="updateTime"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div v-if="scope.row.status ===0 ">
              <el-link
                :underline="false"
                size="mini"
                type="primary"
                @click="editCheck(scope.row)"
              >审核</el-link>
              <el-link
                :underline="false"
                size="mini"
                style="margin-left:20px"
                type="danger"
                @click="editRefuse(scope.row)"
              >拒绝</el-link>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      queryInfo: {
        limit: 15,
        pageNo: 1,
        startTime: '',
        endTime: '',
        memberAccount: '',
        merchantContact: '',
        status: '' // 状态
      },
      activeTime: '',
      status: [{
        value: 0,
        label: '未审核'
      }, {
        value: 1,
        label: '已审核'
      }, {
        value: 2,
        label: '已拒绝'
      }],
      value: '',
      distributionList: [],
      total: 0
    }
  },
  created () {
    this.getDistribution()
  },
  methods: {
    searchDistribution () {
      this.queryInfo.pageNo = 1
      this.getDistribution()
    },
    async getDistribution () {
      const [min, max] = this.activeTime || [undefined, undefined]
      this.queryInfo.startTime = min
      this.queryInfo.endTime = max
      const res = await this.$http.get('/memberSpreadRecord/list', {
        params: this.queryInfo
      })
      this.distributionList = res.data.data.data
      this.total = res.data.data.total
    },
    changeGrantTime () {
      this.queryInfo.pageNo = 1
      this.getDistribution()
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getDistribution()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getDistribution()
    },
    // 审核
    async editCheck (row) {
      // 0-未审核, 1-已审核, 2-已拒绝
      console.log(row)
      row.status = 1
      const { data: res } = await this.$http.put(`/memberSpreadRecord/${row.id}`, row)
      if (res.code !== 0) {
        return this.$message.error('审核失败')
      }
      this.$message.success('审核成功')
      this.getDistribution()
    },
    // 拒绝
    async editRefuse (row) {
      // 0-未审核, 1-已审核, 2-已拒绝
      row.status = 2
      const { data: res } = await this.$http.put(`/memberSpreadRecord/${row.id}`, row)
      if (res.code !== 0) {
        return this.$message.error('拒绝失败')
      }
      this.$message.success('已拒绝')
      this.getDistribution()
    }
  }
}
</script>

<style lang="less" scoped>
.time {
  width: 340px;
  .picker {
    width: 225px;
  }
}
.account {
  margin-left: 325px;
  margin-top: -40px;
  .input {
    width: 200px;
  }
}
.contact {
  margin-left: 600px;
  margin-top: -40px;
  .input {
    width: 200px;
  }
}
.check {
  margin-left: 910px;
  margin-top: -40px;
  .select {
    width: 90px;
  }
}
</style>
